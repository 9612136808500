<template>
  <div class="phoneContent">
    <el-main>
      <!-- 轮播图 -->
      <el-card class="slideshow_card">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item key="1">
            <img class="bgImage" alt="" src="@/assets/images/home/slideshowBg.png">
          </el-carousel-item>
        </el-carousel>
      </el-card>
      <!-- 国内唯⼀全栈⾃主可控⼯业操作系统 -->
      <el-card class="controllable_card">
        <template #header>
          <div class="card-header">
            <span>国内唯⼀全栈⾃主可控⼯业操作系统</span>
          </div>
        </template>
        <div class="contentRow1">
          <span class="textSpan1">1个</span>
          <span class="textSpan2">⼯业现场数据底座</span>
        </div>
        <div class="contentRow2">
          <span class="textSpan1">1个</span>
          <span class="textSpan2">开放式组态平台</span>
        </div>
        <div class="contentRow3">
          <span class="textSpan1">4个</span>
          <span class="textSpan2">核⼼产品+</span>
          <span class="textSpan3">N个</span>
          <span class="textSpan2">扩展产品</span>
        </div>
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item key="1">
            <img class="bgImage" alt="" src="@/assets/images/home/controllableBg1.png">
          </el-carousel-item>
          <el-carousel-item key="1">
            <img class="bgImage" alt="" src="@/assets/images/home/controllableBg2.png">
          </el-carousel-item>
        </el-carousel>
      </el-card>
      <!-- 自主可控的意义-->
      <el-card class="steerable_card">
        <template #header>
          <div class="card-header">
            <span>自主可控的意义</span>
          </div>
        </template>
        <div :class='className1' @mouseover="steerableMouseOver1" @mouseleave="steerableMouseleave">
          <div class="titleDiv">
            <span class="titleSpan">提升产业安全性</span>
          </div>
        </div>
        <div :class='className2' @mouseover="steerableMouseOver2" @mouseleave="steerableMouseleave">
          <div class="titleDiv">
            <span class="titleSpan">推动技术创新<br/>产业升级</span>
          </div>
        </div>
        <div :class='className3' @mouseover="steerableMouseOver3" @mouseleave="steerableMouseleave">
          <div class="titleDiv">
            <span class="titleSpan">培育国内产业生态</span>
          </div>
        </div>
        <div :class='className4' @mouseover="steerableMouseOver4" @mouseleave="steerableMouseleave">
          <div class="titleDiv">
            <span class="titleSpan">万亿级市场规模</span>
          </div>
        </div>
      </el-card>
      <!-- 应用案例 -->
      <el-card class="case_card">
        <template #header>
          <div class="card-header">
            <span>应用案例</span>
          </div>
        </template>
        <div class="caseRow">
          <div class="topDiv">
            <div class="iconDiv">
              <img class="iconImg" alt="" src="@/assets/images/home/TiscoIcon_phone.png">
            </div>
            <div class="titleDiv">
              <span class="titleSpan">太原钢铁</span>
            </div>
          </div>
          <div class="textDiv">
            <span class="textSpan">天行PLC在冷轧的首次应用<br/>
              提供设计-集成-供货-调试一体化解决方案</span>
          </div>
        </div>
        <div class="caseRow">
          <div class="topDiv">
            <div class="iconDiv">
              <img class="iconImg" alt="" src="@/assets/images/home/BaosteelIcon_phone.png">
            </div>
            <div class="titleDiv">
              <span class="titleSpan">上海宝钢</span>
            </div>
          </div>
          <div class="textDiv">
            <span class="textSpan">天行PLC在冷轧年修项目的首次应用<br/>
              提供设计-集成-供货-调试一体化解决方案</span>
          </div>
        </div>
        <div class="caseRow">
          <div class="topDiv">
            <div class="iconDiv">
              <img class="iconImg" alt="" src="@/assets/images/home/HwhgIcon_phone.png">
            </div>
            <div class="titleDiv">
              <span class="titleSpan">广西宏旺</span>
            </div>
          </div>
          <div class="textDiv">
            <span class="textSpan">天行PLC在冷轧厂首次大规模应用<br/>
              提供设计-集成-供货-调试一体化解决方案</span>
          </div>
        </div>
        <div class="imageRow">
          <img class="caseBgImage" alt="" src="@/assets/images/home/caseBg_phone.png">
        </div>
      </el-card>
      <!-- 我们的优势 -->
      <el-card class="advantage_card">
        <template #header>
          <div class="card-header">
            <span>我们的优势</span>
          </div>
        </template>
        <div class="contentRow">
          <div class="advantageRow">
            <div class="contentDiv">
              <div class="iconDiv">
                <img class="iconImg" alt="" src="@/assets/images/home/supportIcon_phone.png">
              </div>
              <div class="titleDiv">
                <span class="titleSpan">宝武的支持</span>
              </div>
            </div>
            <div class="textDiv">
              <span class="textSpan">依托产业规模，为应用试行提供了庞大的业务资源， 凭借深厚的行业积淀，构建起了一系列严谨且全面的验证场景</span>
            </div>
          </div>
          <div class="advantageRow">
            <div class="contentDiv">
              <div class="iconDiv2">
                <img class="iconImg" alt="" src="@/assets/images/home/homeIcon_phone.png">
              </div>
              <div class="titleDiv">
                <span class="titleSpan">强大的工程服务</span>
              </div>
            </div>
            <div class="textDiv">
              <span class="textSpan">由一群经验丰富、技能精湛的技术专家组成的专业的现场服务支撑团队，凭借扎实的专业知识和娴熟的操作技巧迅速应对，为项目的顺利推进提供人力保障。</span>
            </div>
          </div>
          <div class="advantageRow">
            <div class="contentDiv">
              <div class="iconDiv3">
                <img class="iconImg" alt="" src="@/assets/images/home/pencilIcon_phone.png">
              </div>
              <div class="titleDiv">
                <span class="titleSpan">高端的定制化服务</span>
              </div>
            </div>
            <div class="textDiv">
              <span class="textSpan">专业的研发团队运用先进的技术架构与灵活的模块设计理念，
                不仅满足当下的功能诉求，
                更赋予强大的可扩展性，
                使其随着用户业务的发展而不断进化。
              </span>
            </div>
          </div>
        </div>
      </el-card>
      <!-- 联系我们 -->
      <!-- <el-card class="serviceSupport_card">
        <template #header>
          <div class="card-header">
            <span>联系我们</span>
          </div>
        </template>
        <el-row class="topRow">
          <el-col :span="10">
            <div class="titleDiv">
              <span class="titleSpan">热线电话：</span>
            </div>
          </el-col>
          <el-col :span="14">
            <div class="textDiv">
              <span class="textSpan">400-821-0860</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="bottomRow">
          <el-col :span="10">
            <div class="titleDiv">
              <span class="titleSpan">产品邮箱：</span>
            </div>
          </el-col>
          <el-col :span="14">
            <div class="textDiv">
              <span class="textSpan">baosky@baosight.com</span>
            </div>
          </el-col>
        </el-row>
      </el-card> -->
    </el-main>
  </div>
</template>

<script>
import {getAll} from '@/mock/news'
//公共图标按钮
import { Right } from '@element-plus/icons-vue'

export default {
  name: 'PhoneContent',
  components: {
    Right
  },
  data() {
    return {
      className1:'steerableRow1',
      className2:'steerableRow2_hover',
      className3:'steerableRow3',
      className4:'steerableRow4',

      // activeNames: '1',
      // news: [],
      // newsDisplay: [],
    }
  },
  methods: {
    handleChange(val) {
      //console.log(val);
    },
    steerableMouseOver1(){
      this.className1="steerableRow1_hover";
      this.className2="steerableRow2";
      this.className3="steerableRow3";
      this.className4="steerableRow4";
    },
    steerableMouseOver2(){
      this.className1="steerableRow1";
      this.className2="steerableRow2_hover";
      this.className3="steerableRow3";
      this.className4="steerableRow4";
    },
    steerableMouseOver3(){
      this.className1="steerableRow1";
      this.className2="steerableRow2";
      this.className3="steerableRow3_hover";
      this.className4="steerableRow4";
    },
    steerableMouseOver4(){
      this.className1="steerableRow1";
      this.className2="steerableRow2";
      this.className3="steerableRow3";
      this.className4="steerableRow4_hover";
    },
    steerableMouseleave(){
      this.className1="steerableRow1";
      this.className2="steerableRow2_hover";
      this.className3="steerableRow3";
      this.className4="steerableRow4";
    }
    // fetchData(){
    //   this.news = getAll()
    //   if(this.news.length <= 9){
    //     this.newsDisplay = this.news;
    //   }else {
    //     this.newsDisplay = this.news.slice(0,9);
    //   }
    // },
    // gotoDetails(index){
    //   localStorage.setItem("newsKey",this.news[index].key);
    //   this.$router.push({
    //     name: "phoneNewsDetail",
    //     params: {
    //       newsKey: this.news[index].key
    //     }
    //   })
    // }
  },
  mounted() {
    //this.fetchData();
  },
}
</script>

<style lang="less">
@import "@/assets/css/icon/style.css";
@import "@/assets/css/publicStyle.css";
.phoneContent{
  // 轮播图卡片
  .slideshow_card{
    width: 100%;
    height: 190px;
    background: none;
    margin-top: 16px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 16px;
    --el-card-border-radius: 0px;
    .el-card__body{
      // 走马灯
      .el-carousel{
        height: 200px;
        .el-carousel__container{
          .el-carousel__item{
            height: 190px;
            .bgImage{
              width: 100%;
              height: auto;
              border-radius: 10px;
              z-index: -1;
              position: fixed;
              top: 0;
              left: 0;
            }
          }
        }
        .el-carousel__indicators{
          bottom: 10px;
        }
      }
    }
  }
  // "国内唯⼀全栈⾃主可控⼯业操作系统"卡片
  .controllable_card{
    width: 100%;
    height: 339px;
    background: none;
    margin-top: 40px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 16px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 26px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 18px;
          font-weight: bold;
          line-height: 26px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      height: 297px;
      margin-top: 16px;
      padding:0px;
      .contentRow1,.contentRow2,.contentRow3{
        width: 100%;
        height: 20px;
        text-align: center;
        .textSpan1{
          font-family: Source Han Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #0095EE;
        }
        .textSpan2{
          font-family: Source Han Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #FAFAFC;
        }
        .textSpan3{
          font-family: Source Han Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      .contentRow2,.contentRow3{
        margin-top: 16px;
      }
      // 走马灯
      .el-carousel{
        height: 193px;
        margin: 12px 16px 0px 16px;
        .el-carousel__container{
          .el-carousel__item{
            height: 193px;
            .bgImage{
              width: 100%;
              height: auto;
              position: fixed;
              top: 0;
              left: 0;
            }
          }
        }
        .el-carousel__indicators{
          bottom: 6px;
        }
      }
    }
  }
  // "自主可控的意义"卡片
  .steerable_card{
    width: 100%;
    height: 464px;
    background: ~"url(@/assets/images/home/steerableBg.png)";
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 40px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 26px;
      border-bottom:0px;
      margin-top: 36px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 18px;
          font-weight: bold;
          line-height: 26px;
          color: #1D1D1F;
        }
      }
    }
    .el-card__body{
      margin-top:44px;
      .steerableRow1{
        margin: 0px 53px;
        border-width:1px 1px 0px 1px;
        line-height: 60px;
      }
      .steerableRow2{
        margin: 0px 53px;
        border-width:0px 1px;
        line-height: 30px;
      }
      .steerableRow3,.steerableRow4{
        margin: 0px 53px;
        border-width:0px 1px;
        line-height: 60px;
      }
      .steerableRow4{
        margin: 0px 53px;
        border-width:0px 1px 1px 1px;
        line-height: 60px;
      }
      .steerableRow1,.steerableRow2,.steerableRow3,.steerableRow4{
        height: 60px;
        border-style: solid;
        border-color:rgba(35, 44, 58, 0.2);
        text-align: center;
        .titleDiv{
          .titleSpan{
            font-family: Source Han Sans;
            font-size: 18px;
            font-weight: 500;
            color: #333336;
          }
        }
      }
      .steerableRow1_hover,.steerableRow3_hover,.steerableRow4_hover{
        margin:0px 30px;
        line-height: 72px;
      }
      .steerableRow2_hover{
        margin: 0px 30px;
        line-height: 36px;
      }
      .steerableRow1_hover,.steerableRow2_hover,.steerableRow3_hover,.steerableRow4_hover{
        height: 72px;
        text-align: center;
        background: linear-gradient(77deg, #0095EE 1%, #00E09A 100%);
        box-sizing: border-box;
        border: 1px solid rgba(35, 44, 58, 0.2);
        box-shadow: 0px 14px 38px 0px rgba(0, 81, 129, 0.3);
        .titleDiv{
          .titleSpan{
            font-family: Source Han Sans;
            font-size: 18px;
            font-weight: 500;
            color: #FAFAFC;
          }
        }
      }
    }
  }
  // "应用案例"卡片
  .case_card{
    width: 100%;
    height: 695px;
    margin: 0 auto;
    background: none;
    margin-top: 40px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 26px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 18px;
          font-weight: bold;
          line-height: 26px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      margin-top: 16px;
      .caseRow{
        width: 100%;
        height: 114px;
        .topDiv{
          height: 24px;
          margin-top: 20px;
          .iconDiv{
            float: left;
            margin-left: 33%;
            height: 24px;
            .supportIcon{
              width: 50%;
              height: auto;
            }
          }
          .titleDiv{
            float: left;
            height: 24px;
            margin-left: 12px;
            .titleSpan{
              font-family: Source Han Sans;
              font-size: 14px;
              line-height: 24px;
              color: rgba(250, 250, 252, 0.6);
            }
          }
        }
        .textDiv{
          margin-top: 16px;
          width: 100%;
          height: 34px;
          text-align: center;
          .textSpan{
            font-family: Source Han Sans;
            font-size: 12px;
            line-height: 20px;
            color: #FAFAFC;
          }
        }

      }
      .imageRow{
        width: 100%;
        height: 268.8px;
        .caseBgImage{
          width: 100%;
          height: auto;
        }
      }
    }
  }
  // "我们的优势"卡片
  .advantage_card{
    width: 100%;
    height: 398px;
    margin: 0 auto;
    background: none;
    margin-top: 40px;
    margin-bottom: 40px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 26px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 18px;
          font-weight: bold;
          line-height: 26px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      .contentRow{
        width: 100%;
        .advantageRow{
          margin: 16px 30px;
          .contentDiv{
            height: 32px;
            .iconDiv,.iconDiv2,.iconDiv3{
              height: 32px;
              float: left;
              .supportIcon{
                width: 50%;
                height: auto;
              }
            }
            .iconDiv{
              margin-left: 33%;
            }
            .iconDiv2{
              margin-left: 31.5%;
            }
            .iconDiv3{
              margin-left: 30%;
            }
            .titleDiv{
              height: 32px;
              float: left;
              margin-left: 12px;
              .titleSpan{
                font-family: Source Han Sans;
                font-size: 16px;
                line-height: 32px;
                background: linear-gradient(90deg, #0095EE 0%, #00E09A 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }
          }
          .textDiv{
            text-align: justify;
            margin-top: 12px;
            .textSpan{
              font-family: Source Han Sans;
              font-size: 12px;
              line-height: 16px;
              color: #FAFAFC;
              white-space: normal;
            }
          }
        }
      }
    }
  }
  // "解决方案/案例"卡片
  .solution_card{
    height: 473px;
    background: #22262D;
    margin-top: 20px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 16px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 41px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 28px;
          font-weight: bold;
          line-height: 41px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      // 走马灯
      .el-carousel{
        height: 432px;
        margin-top: 32px;
        .el-carousel__container{
          height: 400px;
          .el-carousel__item{
            height: 400px;
            .taigangDiv,.baogangDiv,.hongwangDiv,.guigangDiv{
              width: 100%;
              height: 400px;
              border-radius: 10px;
            }
            .taigangDiv{
              background: ~"url(@/assets/images/productCenter/taigang.png)";
              background-size: 100% 100%;
            }
            .baogangDiv{
              background: ~"url(@/assets/images/productCenter/baogang.png)";
              background-size: 100% 100%;
            }
            .hongwangDiv{
              background: ~"url(@/assets/images/productCenter/hongwang.png)";
              background-size: 100% 100%;
            }
            .guigangDiv{
              background: ~"url(@/assets/images/productCenter/guigang.png)";
              background-size: 100% 100%;
            }
            .contentDiv{
              width: 100%;
              padding: 24px 0px;
              .titleDiv{
                width: 100%;
                height: 35px;
                text-align: center;
                .titleSpan{
                  font-family: Source Han Sans;
                  font-size: 24px;
                  font-weight: bold;
                  line-height: 35px;
                  color: #FAFAFC;
                }
              }
              .textDiv{
                width: 100%;
                height: 80px;
                text-align: center;
                margin-top: 28px;
                ul{
                  margin-block-start: 0em;
                  margin-block-end: 0em;
                  padding-inline-start: 60px;
                  li{
                    width: 100%;
                    min-height: 28px;
                    font-family: Source Han Sans;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FAFAFC;
                    text-align: left;
                  }
                }
                .textSpan{
                  font-family: Source Han Sans;
                  font-size: 14px;
                  line-height: 20px;
                  color: #FAFAFC;
                  word-wrap: break-word;
                }
              }
              .taigangBtnDiv,.baogangBtnDiv,.hongwangBtnDiv,.guigangBtnDiv{
                padding-top: 173px;
                padding-left: 80px;
                // "立即咨询"按钮
                .consultNowBtn{
                  float: left;
                }
                // "了解更多"按钮
                .learnMoreBtn{
                  float: left;
                  margin-left: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
  // "新闻与动态"卡片
  .newsTrends_card{
    height: 420px;
    background: none;
    margin-top: 20px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 16px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 41px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 28px;
          font-weight: bold;
          line-height: 41px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      margin-top: 32px;
      // 走马灯
      .el-carousel{
        height: 347px;
        .el-carousel__container{
          height: 347px;
          .el-carousel__item{
            .newsDiv{
              width: 100%;
              height: 347px;
              border-radius: 10px;
            }
            .newsImg{
              width: 100%;
              height: auto;
            }
            .contentDiv{
              width: 100%;
              margin-top: 10px;
              .titleDiv{
                height: 70px;
                padding: 0px 60px;
                text-align: center;
                .titleSpan{
                  font-family: Source Han Sans;
                  font-size: 16px;
                  line-height: 25px;
                  color: #FAFAFC;
                }
              }
            }
          }
        }
        .el-carousel__indicators{
          display: none;
        }
      }
    }
  }
  // "联系我们"卡片
  .serviceSupport_card{
    height: 140px;
    background: none;
    margin-top: 20px;
    border:0px;
    box-shadow: none !important;
    --el-card-padding: 0px 16px;
    --el-card-border-radius: 0px;
    .el-card__header{
      height: 41px;
      border-bottom:0px;
      .card-header{
        text-align: center;
        span{
          font-family: Source Han Sans;
          font-size: 28px;
          font-weight: bold;
          line-height: 41px;
          color: #FAFAFC;
        }
      }
    }
    .el-card__body{
      margin-top: 32px;
      .topRow,.bottomRow{
        width: 100%;
        height: 30px;
        .titleDiv{
          height: 100%;
          text-align: right;
          .titleSpan{
            font-family: Source Han Sans;
            font-size: 18px;
            font-weight: 500;
            color: #FAFAFC;
          }
        }
        .textDiv{
          height: 100%;
          text-align: left;
          margin-left: 10px;
          .textSpan{
            font-family: Source Han Sans;
            font-size: 18px;
            font-weight: 500;
            color: #FAFAFC;
          }
        }
      }
    }
  }
}
</style>