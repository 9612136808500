<template>
  <div class="wrapper">
    <div class="content">
      <div class="tittle-wrapper">
        <div class="page-tittle">重点新闻</div>
        <div class="tittle">
          {{ tittle }}
        </div>
        <div class="date">
          {{ date }}
        </div>
      </div>
      <div class="news">
        <p>
          为全面贯彻党的二十届三中全会精神，提前布局工业软件国产化，提前熟悉国产工业软件的使用以及运行环境，11月18日，轧钢厂在电气首席工程师王荣的组织下，联合宝信软件技术员针对重庆钢铁现场自动化技术员围绕天行PLC系列产品开展了专题培训，设备管理部、炼铁事业部、炼钢厂、轧钢厂自动化技术人员应邀参与。
          此次培训采取“理论讲解+现场教学”的模式进行，进一步提升了公司技术人员对宝信天行PLC产品的理解。
        </p>
        <div class="img third-news-first-img"></div>
        <p class="subTittle">理论培训，加强天行PLC产品认识</p>
        <p>
          在培训中我们了解到，宝信软件自主研发的全栈自主可控T3、T4系列PLC（可编程逻辑控制器）产品于今年9月20日在上海发布。
          宝信技术员重点介绍了宝信软件全栈自主可控PLC（可编程逻辑控制器）T3、T4系列产品，围绕系统软硬件、技术特点、创新功能、典型案例和优势对比几个方面进行了细致讲解。
          在增进技术人员对设备原理、功能及选型理解的同时，还深入探索了PLC天行系列产品在项目中的实际应用，让技术人员的理论知识得到了巩固。
        </p>
        <div class="img-wrapper-frame">" +
          <div class="third-img-wrapper">
            <div class="img third-news-second-img"></div>
            <div class="img third-news-third-img"></div>
          </div>
        </div>
        <p class="subTittle">实操培训，充分了解天行PLC软硬件系统</p>
        <p>
          此次交流宝信技术人员带来了T3、T4系列PLC以及远程IO模块产品实物，在曾俊伟自动化工作室搭建了自动化控制系统平台，
          围绕如何组态天行PLC、创建工程项目以及使用宝信自研IDE环境编写控制程序为我们的自动化技术员进行了演示。通过演示以及实际上手体验，自动化技术人员对天行PLC的软硬件有了更全面的了解。
          “感谢宝信技术专家提供这么好培训资料与清晰的讲解，让我们受益匪浅！”参训的同志们一致表示。
        </p>
        <div class="img-wrapper-frame">
          <div class="third-img-wrapper">
            <div class="img third-news-fourth-img"></div>
            <div class="img third-news-fifth-img"></div>
          </div>
        </div>
        <p>
          国产天行PLC系列产品的推出对于提升钢铁工业乃至整个制造业的自主可控能力具有深远影响，为应对工业控制软硬件国产化更新浪潮指明了方向。
          通过减少对外部技术的依赖，国家工业安全和经济命脉的安全性能够得到有效增强。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Meeting',
  components: {},
  props:{
    tittle: "",
    date: "",
  },
  methods: {

  },
  onBeforeUnmount() {
    localStorage.removeItem('newsKey')
  },
}

</script>

<style lang="less" scoped>
.wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  .content{
    width: 1090px;
    margin-bottom: 147px;
    .tittle-wrapper{
      width: 1090px;
      height: 275px;
      border-bottom: 2px solid rgb(57, 65, 78);
      margin-bottom: 48px;
      opacity: 1;

      /* 自动布局 */
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 48px;

      z-index: 0;

      .page-tittle{
        margin-top: 48px;
        width: 1090px;
        height: 26px;
        opacity: 1;

        /* 标题小 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 0;
      }
      .tittle{
        width: 1090px;
        height: 82px;
        opacity: 1;

        /* 标题大 */
        font-family: Source Han Sans;
        font-size: 28px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 1;
      }
      .date{
        width: 1090px;
        height: 23px;
        margin-bottom: 48px;
        opacity: 1;

        /* 正文 */
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 2;
      }
    }
    .news{
      .img{
        width: 409px;
        height: 287px;
        opacity: 1;
      }
      .third-news-first-img{
        background: url('@/assets/images/news/detail/3-1.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 294px;
      }
      .img-wrapper-frame{
        display: flex;
        justify-content: center;
        .third-img-wrapper{
          display: flex;
          flex-direction: row;
          column-gap: 24px;
          .third-news-second-img{
            background: url('@/assets/images/news/detail/3-2.png') no-repeat;
            background-size: 100% 100%;
          }
          .third-news-third-img{
            background: url('@/assets/images/news/detail/3-3.png') no-repeat;
            background-size: 100% 100%;
          }
          .third-news-fourth-img{
            background: url('@/assets/images/news/detail/3-4.png') no-repeat;
            background-size: 100% 100%;
          }
          .third-news-fifth-img{
            background: url('@/assets/images/news/detail/3-5.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      p{
        display: block;
        /* 正文 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0em;
        text-indent: 2em;
        line-height:28px;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: rgba(250, 250, 252,0.8);
      }
      .subTittle{
        width: 1090px;
        height: 26px;
        margin-bottom: 24px;
        margin-top: 48px;
        opacity: 1;

        /* 标题小 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;
      }
    }
  }
}
</style>
