<template>
<div class="wrapper">
  <div class="content">
    <div class="tittle-wrapper">
      <div class="page-tittle">重点新闻</div>
      <div class="tittle">
        {{tittle}}
      </div>
      <div class="date">
        {{ date }}
      </div>
    </div>
    <div class="news">
      <p>
        9月20日，在中华人民共和国迎来75周年华诞之际，上海宝信软件股份有限公司（以下简称“宝信软件”）自主研发的工业控制系统核心部件——全栈自主可控PLC（可编程逻辑控制器）T3、T4系列产品隆重发布。
        中国宝武党委书记、董事长胡望明，中国宝武党委常委、副总经理费鹏等领导出席大会并致辞。
      </p>
      <div class="img first-img"></div>
      <p>
        钢铁工业是国民经济的重要基础产业，是建设现代化强国的重要支撑，其生产控制系统能否实现自主可控，对于保障国家工业安全、防止受制于人具有非常重要的意义。
        宝信软件认真学习贯彻党的二十届三中全会精神和习近平总书记关于科技创新重要论述，在国家相关部委、上海市有关部门以及集团公司的关心和支持下，
        依托中国宝武深厚的行业积累及丰富的应用场景，历经十余年技术沉淀与创新研发，成功推出天行系列全栈自主可控PLC系列产品。
      </p>
      <p>
        天行系列PLC产品从操作系统，到运行时软件，再到编程环境实现了全栈自主可控，具有高度的可靠性、稳定性和灵活性，可广泛应用于各行业自动化领域。
        天行IDE（集成开发环境）是国内首套面向大型PLC的云原生开发平台，独立于操作系统，易于跨平台快速部署，开放式平台架构可集成第三方应用。
        天行DSF（数字工业现场）让参与制造的各个系统在去中心化的环境中运行，以天行PLC为核心的任意节点之间实时数据可以无缝交流，真正实现工业现场全域互通、数实融合。
      </p>
      <p>
        中国宝武党委常委、副总经理费鹏表示，宝信软件作为中国宝武数实融合加速器，一直以来秉持着科技引领、创新驱动的发展理念，在工业软件领域不断探索和突破。
        此次全栈自主可控 PLC 产品的推出，是宝信软件团队攻坚克难、勇攀高峰的成果，也是中国宝武在推动国产化替代、提升自主创新能力道路上的重要里程碑。
        集团公司期待，宝信软件能够以此次发布会为新的起点，不断优化和完善产品，在性能、稳定性和可靠性等方面达到国际领先水平。
        中国宝武将一如既往支持宝信软件发展，为其提供更广阔的平台和更有力的资源保障。
      </p>
      <div class="img second-img"></div>
      <p>
        中国宝武数字工业研究院院长，宝信软件党委书记、董事长夏雪松表示，中国宝武数字工业研究院和宝信软件广大科技工作者经过不懈耕耘，迎来了全栈自主可控PLC产品的发布。
        这是中国宝武数字工业研究院和宝信软件自主研发、自主创新的重大成果，也是工业控制领域国产化替代的重要突破。
        公司将积极响应国家号召，贯彻落实集团公司决策部署，围绕核心功能，打造核心竞争力，坚持创新引领，加大研发投入，持久攻关，不断优化产品性能，拓展应用领域，为我国工业自动化、数字化、智能化发展贡献更多力量。
      </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Debut',
  props:{
    tittle: "",
    date: "",
  },
  components: {},
  methods: {

  },
  onBeforeUnmount() {
    localStorage.removeItem('newsKey')
  },
}

</script>

<style lang="less" scoped>
.wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  .content{
    width: 1090px;
    margin-bottom: 147px;
    .tittle-wrapper{
      width: 1090px;
      height: 275px;
      border-bottom: 2px solid rgb(57, 65, 78);
      margin-bottom: 48px;
      opacity: 1;

      /* 自动布局 */
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 48px;

      z-index: 0;

      .page-tittle{
        margin-top: 48px;
        width: 1090px;
        height: 26px;
        opacity: 1;

        /* 标题小 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 0;
      }
      .tittle{
        width: 1090px;
        height: 82px;
        opacity: 1;

        /* 标题大 */
        font-family: Source Han Sans;
        font-size: 28px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 1;
      }
      .date{
        width: 1090px;
        height: 23px;
        margin-bottom: 48px;
        opacity: 1;

        /* 正文 */
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 2;
      }
    }
    .news{
      .img{
        width: 409px;
        height: 287px;
        opacity: 1;
      }
      .first-img{
        background: url("@/assets/images/news/first.png") no-repeat;
        background-size: 100% 100%;
        margin-left: 294px;
      }
      .second-img{
        background: url('@/assets/images/news/second.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 294px;
      }
      p{
        display: block;
        /* 正文 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0em;
        text-indent: 2em;
        line-height:28px;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: rgba(250, 250, 252,0.8);
      }
    }
  }
}
</style>
