<template>
  <div>
    <component :is="currentComponent"  :tittle="tittle" :date="date"></component>
  </div>
</template>

<script >
import {getNewsByKey} from "@/mock/news";
import DebutPhone from "@/views/NewsCenter/detail/2024_09_20/DebutPhone.vue"
import WuHanBaosightPhone from "@/views/NewsCenter/detail/2025_01_17/WuHanBaosightPhone.vue";
import IPlatPhone from "@/views/NewsCenter/detail/2024_11_26/IPlatPhone.vue";
import MeetingPhone from "@/views/NewsCenter/detail/2024_11_26/MeetingPhone.vue";
export default {
  name: 'NewsDetailPhone',
  components: {
    DebutPhone,WuHanBaosightPhone,IPlatPhone,MeetingPhone
  },
  data() {
    return {
      currentComponent: null,
      tittle: null,
      date: null,
    }
  },
  created() {
    let newsKey = this.$route.params.newsKey;
    if(newsKey === undefined){
      newsKey = localStorage.getItem('newsKey');
      let newsInfo = getNewsByKey(newsKey);
      this.tittle = newsInfo.tittle;
      this.date = newsInfo.date;
      this.currentComponent = newsInfo.component + "Phone";
    }
  },
}
</script>

<style scoped>

</style>
