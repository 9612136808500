<template>
  <div>
    <component :is="currentComponent"  :tittle="tittle" :date="date"></component>
  </div>
</template>

<script >
import {getNewsByKey} from "@/mock/news";
import Debut from "@/views/NewsCenter/detail/2024_09_20/Debut.vue"
import WuHanBaosight from "@/views/NewsCenter/detail/2025_01_17/WuHanBaosight.vue";
import IPlat from "@/views/NewsCenter/detail/2024_11_26/IPlat.vue";
import Meeting from "@/views/NewsCenter/detail/2024_11_26/Meeting.vue";
export default {
  name: 'NewsDetail',
  components: {
    Debut,WuHanBaosight,IPlat,Meeting
  },
  data() {
    return {
      currentComponent: null,
      tittle: null,
      date: null,
    }
  },
  created() {
    let newsKey = this.$route.params.newsKey;
    if(newsKey === undefined){
      newsKey = localStorage.getItem('newsKey');
      let newsInfo = getNewsByKey(newsKey);
      this.tittle = newsInfo.tittle;
      this.date = newsInfo.date;
      this.currentComponent = newsInfo.component;
    }
  },
}
</script>

<style scoped>

</style>
