<template>
  <div class="wrapper">
    <div class="content">
      <div class="tittle-wrapper">
        <div class="page-tittle">重点新闻</div>
        <div class="tittle">
          {{ tittle}}
        </div>
        <div class="date">
          {{ date }}
        </div>
      </div>
      <div class="news">
        <p>
          2024年11月8日至10日，备受瞩目的国家工业软件大会在上海盛大举行。此次大会以“AI for Engineering•工业软件赋能新型工业化”为主题，汇聚了来自全国乃至全球的顶尖院士、
          专家学者与行业精英，共同就中国工业智能领域的战略布局、技术创新应用及产业融合发展展开深度对话与智慧碰撞。
        </p>
        <p>
          作为本次大会的参展单位之一，上海宝信软件股份有限公司(（以下简称“宝信软件”)携三大创新产品——iPlat-BA工业基础自动化软件、天行IDE自动化集成软件开发平台及iHyperDB分布式高性能实时数据库软件惊艳亮相。
          宝信软件总工程师丛力群先生与数字工业研究院(以下简称“数工院”)产品发展部总经理龚海斌先生亲临现场，与来自五湖四海的业界同仁共襄盛举，共同见证了中国工业软件发展的辉煌时刻。
        </p>
        <p>
          本次大会亮点纷呈，一场别开生面的工业软件成果展尤为引人注目。30余家企业聚焦创新成果和未来集群布局，展示了中国在工业软件领域的最新模型、先进算法和软件成果。
          这些成果不仅彰显了中国工业软件的自主创新能力和领先水平，更为未来工业发展提供了强大的技术支撑和广阔的发展空间。
        </p>
        <div class="img first-win-img"></div>
        <p>
          在工业软件成品展示环节，浙江大学王文海教授与宝信软件丛力群先生及龚海斌先生，深入探讨了宝信软件的三大核心产品。他们不仅聚焦于iPlat-BA这一全自主可控的新一代SCADA软件系统在数据采集、控制、计算和展示方面的集成优势，
          以及其对降低操作与维护成本、提供灵活解决方案的积极影响；还深入讨论了天行IDE作为全栈自主可控工控系统的集成开发平台，如何凭借其统一的工程组态和软件项目环境，促进用户快速直观地开发调试自动化系统，并广泛应用于多个工业领域。
          此外，针对高性能实时数据库软件iHyperDB，他们探讨了其海量存储、高效检索及统计分析等强大功能，以及该产品所展现出的可靠安全性能。在全面审视产品特性的基础上，三位专家还共同提出了对未来工业软件发展的独到见解和前瞻性思考。
        </p>
        <div class="img second-win-img"></div>
        <p>
          特别值得一提的是，宝信软件自主研发的工业基础自动化软件iPlat-BA在此次大会上荣获了“创新工业软件”奖。该软件专注于工业现场生产操作和监控场景，凭借其卓越的性能、稳定性和易用性，
          赢得了评委和业界的一致好评。这一荣誉不仅是对宝信软件技术创新能力的肯定，更是对中国工业软件自主创新能力的有力证明。
        </p>
        <div class="second-img-wrapper">
          <div class="third-img"></div>
          <div class="fourth-img"></div>
        </div>
        <p>
          随着2024国家工业软件大会的圆满落幕，中国工业软件的发展也将迎来新的篇章。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IPlat",
  components: {},
  props:{
    tittle: "",
    date: "",
  },
  methods: {},
  onBeforeUnmount() {
    localStorage.removeItem('newsKey')
  }
}
</script>

<style lang="less" scoped>
.wrapper{
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  justify-content: center;
  .content{
    width: 1090px;
    margin-bottom: 147px;
    .tittle-wrapper{
      width: 1090px;
      height: 275px;
      border-bottom: 2px solid rgb(57, 65, 78);
      margin-bottom: 48px;
      opacity: 1;

      /* 自动布局 */
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 48px;

      z-index: 0;

      .page-tittle{
        margin-top: 48px;
        width: 1090px;
        height: 26px;
        opacity: 1;

        /* 标题小 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 0;
      }
      .tittle{
        width: 1090px;
        height: 82px;
        opacity: 1;

        /* 标题大 */
        font-family: Source Han Sans;
        font-size: 28px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 1;
      }
      .date{
        width: 1090px;
        height: 23px;
        margin-bottom: 48px;
        opacity: 1;

        /* 正文 */
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: #FAFAFC;

        z-index: 2;
      }
    }
    .news{
      .img{
        width: 409px;
        height: 287px;
        opacity: 1;
      }
      .first-win-img{
        background: url('@/assets/images/news/detail/1.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 294px;
      }
      .second-win-img{
        background: url('@/assets/images/news/detail/2.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 294px;
      }
      .second-img-wrapper{
        display: flex;
        flex-direction: row;
        padding-left: 245px;
        .third-img{
          width: 214px;
          height: 245px;
          opacity: 1;
          margin-right: 72px;
          background: url('@/assets/images/news/detail/3.png') no-repeat;
          background-size: 100% 100%;
          z-index: 0;
        }
        .fourth-img{
          width: 315px;
          height: 238px;
          opacity: 1;

          background: url('@/assets/images/news/detail/4.png') no-repeat;
          background-size: 100% 100%;

          z-index: 1;
        }
      }
      p{
        /* 正文 */
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;
        text-indent: 2em;
        line-height:28px;

        font-variation-settings: "opsz" 0.7;
        /* 最浅 */
        color: rgb(250, 250, 252);
      }
    }
  }
}
</style>
